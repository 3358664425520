





















































































































import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepEmploymentSituationViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-employment-situation-view-model';

@Component({ name: 'FlagshipProfilingStepEmploymentSituation' })
export default class FlagshipProfilingStepEmploymentSituation extends Vue {
  flagship_situation_view_model = Vue.observable(
    new FlagshipProfilingStepEmploymentSituationViewModel(this),
  );

  created() {
    this.flagship_situation_view_model.initialize();
  }
}
